import request from "../customUtil/request"

export async function getNewsListData(param) {
  return request("/admin/news/list-news", {
    method: "GET",
    params: param,
  })
}

export async function createNews(data) {
  return request("/admin/news", {
    method: "POST",
    data: data,
  })
}

export async function updateNews(id, data) {
  return request(`/admin/news/update-data/${id}`, {
    method: "PUT",
    data: data,
  })
}
export async function updateStatusNews(id, data) {
  return request(`/admin/news/change-status/${id}`, {
    method: "PUT",
    data: data,
  })
}
export async function getNewsDataById(id) {
  return request(`/admin/news/news-detail/${id}`, {
    method: "GET",
  })
}
export async function deleteNews(id) {
  return request(`/admin/news/delete/${id}`, {
    method: "DELETE",
  })
}

export async function getListNews(param) {
  return request("/store/news/list-news", {
    method: "GET",
    params: param,
  })
}

export async function getNewsDataByIdForRead(id) {
  return request(`/store/news/news-detail/${id}`, {
    method: "GET",
  })
}
