import Topbar from "../../../components/organisms/topbar"
import React, { useEffect, useState } from "react"
import CustomLayout from "../../../Layouts/Layout"
import CreateNews from "../../../domain/superAdNews/createNews"
import "../styles.scss"

const CreateNewIndex = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])
  return (
    <>
      {isLoaded && document && (
        <div className="customContent">
          <Topbar />
          <CustomLayout selectedPage={<CreateNews />} />
        </div>
      )}
    </>
  )
}

export default CreateNewIndex
